import '../../assets/scss/pages/_crear-editar-general.scss';
import React, { useState, useEffect } from 'react';
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import Navbar from "../../components/Navbar";
import Select from "../../components/Select";
import FileUploadButton from "../../components/FileUploadButton";
import DeleteIcon from '../../assets/icons/delete-black.svg';
import goBackIcon from '../../assets/icons/arrow-left.svg';
import { AlertModalType, InputType } from "../../shared/types";
import { useNavigate, useLocation } from 'react-router-dom';
import AlertModal from "../../components/AlertModal/index";
import PermissionsComponent from "../../components/PermissionsComponent/index";
import { AxiosError, AxiosResponse } from "axios";
import { appConfig } from '../../config/app';

export type DatosType = {
  [key: string]: string | any[];
};

export type TabElemento = {
  id: string;
  label: string;
  campos: CampoElemento[];
};

export type CampoElemento = {
  id: string;
  label?: string;
  flex?: string;
  elementos?: {
    name: string;
    label?: string;
    placeholder?: string;
    flex?: string;
    typeElem: 'input' | 'select' | 'textarea' | 'image' | 'permissions';
    type?: string;
    src?: string;
    defaultSrc?:string;
    readOnly?:boolean;
    options?: { text: string; value: string }[]
  }[];
  tabs?: TabElemento[];
};

type FormularioProps = {
  id?: number;
  title: string;
  elementsType: string;
  datos: DatosType;
  camposFormulario: CampoElemento[];
  onSave: (formData?: DatosType) => Promise<AxiosResponse<any, any> | null>;
  onCancel: () => void;
  onDelete: (id?: number) => Promise<AxiosResponse<any, any> | null>;
};

const CreatAndEditGeneralPage: React.FC<FormularioProps> = ({ id, title, datos, elementsType, camposFormulario, onSave, onCancel, onDelete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);
  const from = (location.state as { from?: string } | undefined)?.from || 'atrás';
  const [formData, setFormData] = useState<DatosType>(datos);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>('campos');
  const baseURL =  `${appConfig.apiConfig.baseURL}`;
  const [selectedPhoto, setSelectedPhoto] = useState<string>('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleSave = async (formData?: DatosType): Promise<AxiosResponse<any, any> | null> => {
    const config = {
      title: `${elementsType} guardado correctamente`,
      type: 'ok' as AlertModalType,
      onSubmit: handleClose
    };

    try {
      // Combina el valor del tab seleccionado con el formData para enviar el modo de guardado
      const dataToSave = {
        ...formData,
        mode: activeTabKey ?? '',
        foto: selectedPhoto && selectedPhoto !== '' ? selectedPhoto : formData?.foto ?? ''
      };

      // Llama a la función onSave pasándole formData
      const result: AxiosResponse | null = await onSave(dataToSave);

      if (result?.data.success === false) {
        config.title = 'Error al guardar: ' + result?.data.message;
        config.type = 'error';
        config.onSubmit = handleClose;
      } else {
        setUnsavedChanges(false);
      }
    } catch (error: any) {
      let message = error.toString();
      if (error instanceof AxiosError) {
        const message = error.response?.data?.message ?? error;
      }

      console.error('Error al guardar los datos:', error);
      config.title = 'Error al guardar: ' + message;
      config.type = 'error';
      config.onSubmit = handleClose;
    }

    setModalConfig(config);

    return null;
  };

  const handleCancel = () => {
    if(unsavedChanges === true) {
      const config = {
        title: 'Regresar sin guardar',
        description: 'Al continuar, no se guardarán los cambios realizados',
        type: 'warning' as AlertModalType,
        onSubmit: onCancel,
        onClose: handleClose
      };
      setModalConfig(config);
    } else {
      navigate(-1);
    }
  };

  const handleDelete = () => {
    const config = {
      title: `Eliminar ${elementsType}`,
      description: 'Al continuar, se perderán los datos y no podrás recuperarlos',
      type: 'warning' as AlertModalType,
      onSubmit: confirmDelete,
      onClose: handleClose
    };
    setModalConfig(config);
  };

  const confirmDelete = async() => {
    // Llama a la función onDelete pasándole formData
    const result: AxiosResponse | null = await onDelete(id);

    const config = {
      title: `${elementsType} borrado correctamente`,
      type: 'ok' as AlertModalType,
      onSubmit: () => { navigate(-1) }
    };

    if (result?.data.error) {
      config.title = 'Error al guardar: ' + result?.data.error;
      config.type = 'error';
    }

    setModalConfig(config);
  };

  const handleClose = () => {
    if(unsavedChanges === true) {
      setModalConfig(null);
    } else {
      navigate(-1);
    }
  };

  const handleChange = (campo: keyof DatosType, valor: string | number) => {
    // Convierte el valor a cadena si es un número
    const valorComoCadena = typeof valor === 'number' ? String(valor) : valor;
    setFormData({ ...formData, [campo]: valorComoCadena });

    setUnsavedChanges(true);
  };

  const handleCheckboxChange = (idCheckbox: number, isChecked: boolean) => {

    setFormData(prevFormData => {
      if (isChecked) {
        // Si está marcado, lo añadimos al array
        const prevPermisos = Array.isArray(prevFormData.permisos) ? prevFormData.permisos : [];
        const nuevoArray = [...prevPermisos, idCheckbox];
        return { ...prevFormData, permisos: nuevoArray };
      } else {
        // Si no está marcado, lo eliminamos del array
        if (Array.isArray(prevFormData.permisos)) {
          const nuevoArray = prevFormData.permisos.filter((permiso: number) => permiso !== idCheckbox);
          return { ...prevFormData, permisos: nuevoArray };
        } else {
          return { ...prevFormData, permisos: [] };
        }
      }
    });
  };

  useEffect(() => {
    setFormData(datos);
  }, [datos]);

  const handleTabChange = (campoIndex: number, tabIndex: number) => {
    setActiveTab(tabIndex);

    const campo = camposFormulario[campoIndex];
    if (campo && campo.tabs) {
      const tab = campo.tabs[tabIndex];
      if (tab) {
        setActiveTabKey(tab.id);
      }
    }
  };

  const handleFotoSubmit = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result as string;
      setSelectedPhoto(imageUrl);
    };
    reader.readAsDataURL(file);
  };
  
  return (
    <div className={`crear-editar-page ${elementsType}`}>

      <Navbar />

      <div className="main-content">
        
        <div className="headers">
          <div>
            <Button color="transparent" onClick={handleCancel}>
              <img src={goBackIcon} alt="Volver atrás" />
              Regresar a {from}
            </Button>
            <h2 className="title">{title}</h2>
          </div>

          <div className="headers-button-container">
            <Button color="transparent" onClick={handleDelete}>
              <img src={DeleteIcon} alt="Eliminar" />
              Eliminar {elementsType}
            </Button>
            <Button color="outline" onClick={handleCancel}>Cancelar</Button>
            <Button color="primary" onClick={() => handleSave(formData)}>Guardar</Button>
          </div>
        </div>

        {camposFormulario.map((campo, campoIndex) => (
          <div>
            {campo.tabs && campo.tabs.length > 0 && (
              <>
                <div className="tabs-container">
                  {campo.tabs.map((tab, tabIndex) => (
                    <button
                      key={tab.id}
                      onClick={() => handleTabChange(campoIndex, tabIndex)}
                      className={tabIndex === activeTab ? 'active tab-seleccionada' : 'tab-no-seleccionada'}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>

                <div className="elements">
                  {campo.tabs[activeTab].campos.map((subCampo) => (
                    <div key={subCampo.id} style={{ alignItems: 'flex-start' }}>
                      {subCampo.label && <label style={{ flex: subCampo.flex }}>{subCampo.label}</label>}

                      {subCampo.elementos && (
                        <>
                        {subCampo.elementos.map((elemento) => (
                          <React.Fragment key={elemento.name}>
                            {elemento.typeElem === 'input' && (
                              <Input
                                key={elemento.name}
                                name={elemento.name}
                                style={{ flex: elemento.flex }}
                                label={(elemento as { label?: string }).label || ''}
                                placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                                value={String(formData[elemento.name as keyof DatosType]) != 'undefined' ? String(formData[elemento.name as keyof DatosType]) : ''}
                                type={(elemento as { type?: InputType }).type || 'text'}
                                onChange={(value) => handleChange(elemento.name as keyof DatosType, String(value))}
                                readOnly={elemento.readOnly}
                              />
                            )}

                            {elemento.typeElem === 'textarea' && (
                              <Textarea
                                key={elemento.name}
                                name={elemento.name}
                                style={{ flex: elemento.flex }}
                                label={(elemento as { label?: string }).label || ''}
                                placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                                value={String(formData[elemento.name as keyof DatosType]) ?? ''}
                                onChange={(value) => handleChange(elemento.name as keyof DatosType, value)}
                                readOnly={elemento.readOnly}
                              />
                            )}

                            {elemento.typeElem === 'select' && (
                              <Select
                                style={{ flex: elemento.flex }}
                                name={elemento.name}
                                label={(elemento as { label?: string }).label || ''}
                                placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                                options={(elemento as { options?: { text: string; value: string }[] }).options || []}
                                value={String(formData[elemento.name as keyof DatosType]) != 'undefined' ? String(formData[elemento.name as keyof DatosType]) : ''}
                                onChange={(event) => handleChange(elemento.name as keyof DatosType, event.target.value)}
                              />
                            )}

                            {elemento.typeElem === 'image' && (
                              <div className='image-container-row' style={{ flex: elemento.flex }}>
                                <img src={selectedPhoto && selectedPhoto !== '' ? selectedPhoto : (formData['foto'] !== undefined && (String(formData['foto']) !== '') ? baseURL + String(formData['foto']) : elemento.defaultSrc) }
                                     alt="Foto" style={{ padding: String(formData['foto']) === '' ? '16px' : '0' }} />
                                <FileUploadButton type='foto' onSubmit={handleFotoSubmit} />
                              </div>
                            )}

                            {elemento.typeElem === 'permissions' && (Array.isArray(formData.permisos) || formData.permisos == undefined) && (
                              <PermissionsComponent
                                permisosSeleccionados={Array.isArray(formData.permisos) && formData.permisos !== undefined ? formData.permisos : []}
                                onChangeCheckbox={(id: string, isChecked: boolean) => handleCheckboxChange(Number(id), isChecked)} />
                            )}
                          </React.Fragment>
                        ))}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}

        <div className="elements">
          {camposFormulario.map((campo) => (
            <>
            {!campo.tabs && (
              <div key={campo.id}>
                {campo.label && <label style={{ flex: campo.flex }}>{campo.label}</label>}

                {campo.elementos && (
                  <>
                  {campo.elementos.map((elemento) => (
                    <React.Fragment key={elemento.name}>
                      {elemento.typeElem === 'input' && (
                        <Input
                          key={elemento.name}
                          name={elemento.name}
                          style={{ flex: elemento.flex }}
                          label={(elemento as { label?: string }).label || ''}
                          placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                          value={String(formData[elemento.name as keyof DatosType]) != 'undefined' ? String(formData[elemento.name as keyof DatosType]) : ''}
                          type={(elemento as { type?: InputType }).type || 'text'}
                          onChange={(value) => handleChange(elemento.name as keyof DatosType, String(value))}
                          readOnly={elemento.readOnly}
                        />
                      )}

                      {elemento.typeElem === 'textarea' && (
                        <Textarea
                          key={elemento.name}
                          name={elemento.name}
                          style={{ flex: elemento.flex }}
                          label={(elemento as { label?: string }).label || ''}
                          placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                          value={String(formData[elemento.name as keyof DatosType]) ?? ''}
                          onChange={(value) => handleChange(elemento.name as keyof DatosType, value)}
                          readOnly={elemento.readOnly}
                        />
                      )}

                      {elemento.typeElem === 'select' && (
                        <Select
                          style={{ flex: elemento.flex }}
                          label={(elemento as { label?: string }).label || ''}
                          placeholder={(elemento as { placeholder?: string }).placeholder || ''}
                          options={(elemento as { options?: { text: string; value: string }[] }).options || []}
                          value={String(formData[elemento.name as keyof DatosType]) != 'undefined' ? String(formData[elemento.name as keyof DatosType]) : ''}
                          onChange={(event) => handleChange(elemento.name as keyof DatosType, event.target.value)}
                        />
                      )}

                      {elemento.typeElem === 'image' && (
                        <div className='image-container-row' style={{ flex: elemento.flex }}>
                          <img src={selectedPhoto && selectedPhoto !== '' ? selectedPhoto : (formData['foto'] !== undefined && (String(formData['foto']) !== '') ? baseURL + String(formData['foto']) : elemento.defaultSrc) }
                                 alt="Foto" style={{ padding: String(formData['foto']) === '' ? '16px' : '0' }} />
                            <FileUploadButton type='foto' onSubmit={handleFotoSubmit} />
                        </div>
                      )}

                      {elemento.typeElem === 'permissions' && (Array.isArray(formData.permisos) || formData.permisos == undefined) && (
                        <PermissionsComponent
                          permisosSeleccionados={Array.isArray(formData.permisos) && formData.permisos !== undefined ? formData.permisos : []}
                          onChangeCheckbox={(id: string, isChecked: boolean) => handleCheckboxChange(Number(id), isChecked)} />
                      )}
                    </React.Fragment>
                  ))}
                  </>
                )}
              </div>
            )}
            </>
          ))}
        </div>

        <div className="buttons-container">
          <Button color="outline" onClick={handleCancel}>Cancelar</Button>
          <Button color="primary" onClick={() => handleSave(formData)}>Guardar</Button>
        </div>

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={(modalConfig.onSubmit ? modalConfig.onSubmit : handleSave) as (() => void) | undefined}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleClose}
          />
        )}

      </div>
    </div>
  );
};

export default CreatAndEditGeneralPage;