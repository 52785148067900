import Select from "../../../../components/Select/index";
import Button from "../../../../components/Button/index";
import CloseIcon from '../../../../assets/icons/close.svg';

interface ModalProps {
  onClose: () => void;
  onSubmit: () => void;
  options: { value: string; text: string }[];
  value: string;
  onChange: (newValue: string) => void;
}

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, options, value, onChange }) => {
  return (
    <div>
      <div className="modal">
        <div className="modal-header">
          <img src={CloseIcon} onClick={onClose} alt="Close Icon" />
        </div>
        <div className="modal-body">
          <p>Descargar grupo de elementos específico</p>
          <p>Selecciona que grupo quieres descargar</p>
        </div>
        <Select placeholder="Selecciona el grupo"
                options={options}
                value={value}
                onChange={(event) => onChange(event.target.value)} />
        <div className="modal-footer">
          <Button color="outline" onClick={onClose}>Cancelar</Button>
          <Button color="primary" onClick={onSubmit}>Descargar</Button>
        </div>
      </div>
      <div className="modal-backdrop"></div>
    </div>
  );
};

export default Modal;