export type BoolEnum = 'Sí' | 'No';

export type Color =
  | 'primary'
  | 'outline'
  | 'warning'
  | 'success'
  | 'danger'
  | 'new'
  | 'disabled'
  | 'white'
  | 'transparent'
  | 'primary-warning'
  | 'outline-warning'
  | 'primary-ok'
  | 'primary-error';

export type Size = 'sm' | 'md' | 'lg';

export type InputType =
  | 'text'
  | 'hidden'
  | 'file'
  | 'password'
  | 'email'
  | 'number'
  | 'date'
  | 'time'
  | 'file';

export type AlertModalType =
  | 'ok'
  | 'warning'
  | 'error';

export const getButtonColor = (estado: string): Color => {
  switch (estado) {
    case 'enviada':
      return 'success';
      break;
    case 'nueva':
      return 'new';
      break;
    case 'planificada':
    case 'modificada':
      return 'warning';
      break;
    case 'rechazada':
      return 'danger';
      break;
    default:
      return 'transparent'
  }
};