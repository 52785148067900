import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Polygon, useMap } from 'react-leaflet';
import { LatLngTuple, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerIcon from '../../../assets/icons/marker.svg';
import Modal from "../ModalMapa/index";
import proj4 from 'proj4';
import * as wellknown from 'wellknown';

interface MapComponentProps {
  elements: any[];
  tipo: string;
}

const colorPalette = [
  '#FF1493', // Rosa
  '#FF4500', // Naranja Rojizo
  '#32CD32', // Verde Lima
  '#FF69B4', // Rosa Claro
  '#00BFFF', // Azul Claro
  '#FF0000', // Rojo
  '#FFA500', // Naranja
  '#FFFF00', // Amarillo
  '#0000FF', // Azul
];

const MapComponent: React.FC<MapComponentProps> = ({ elements, tipo }) => {
  const defaultCenter: LatLngTuple = [40.4378373,-3.8443425];

  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);

  const customIcon = new Icon({
    iconUrl: MarkerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const handleMarkerClick = (markers: any[]) => {
    setSelectedMarker(markers);
  };

  const handleCloseModal = () => {
    setSelectedMarker(null);
  };

  return (
    <MapContainer center={defaultCenter} zoom={13} style={{ height: '750px', width: '100%', zIndex: 99999 }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <MapComponentContent
        elements={elements}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
        customIcon={customIcon}
        tipo={tipo}
      />

    </MapContainer>
  );
};

interface MapComponentContentProps {
  elements: any[];
  selectedMarker: any | null;
  setSelectedMarker: React.Dispatch<React.SetStateAction<any | null>>;
  customIcon: Icon;
  tipo: string;
}

const MapComponentContent: React.FC<MapComponentContentProps> = ({
 elements,
 selectedMarker,
 setSelectedMarker,
 customIcon,
 tipo
}) => {
  const map = useMap();
  let bounds: L.LatLngBounds | null = null;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleMarkerClick = (marker: any) => {
    setSelectedMarker(marker);
  };

  const handleNextMarker = () => {
    const nextIndex = (currentIndex + 1) % filteredElements.length;
    setCurrentIndex(nextIndex);
    setSelectedMarker(filteredElements[nextIndex]);
  };

  const handlePrevMarker = () => {
    const prevIndex = (currentIndex - 1 + filteredElements.length) % filteredElements.length;
    setCurrentIndex(prevIndex);
    setSelectedMarker(filteredElements[prevIndex]);
  };

  const filteredElements = elements
    ? elements.filter((elem) => elem.coordenadas && elem.coordenadas.startsWith('POINT'))
    : [];

  const coordinatesCountMap = new Map<string, number>();

  // Itera sobre los elementos filtrados para contar las coordenadas duplicadas
  filteredElements.forEach((elem) => {
    const coordinates = elem.coordenadas;
    coordinatesCountMap.set(coordinates, (coordinatesCountMap.get(coordinates) || 0) + 1);
  });

  const markers = filteredElements.map((elem, index) => {
    const latLng: number[] = parseCoordenadas(elem.coordenadas);
    const latLngTuple: L.LatLngTuple = [latLng[0], latLng[1]];
    const latLngObject: L.LatLngExpression = latLngTuple;
    const coordinates = elem.coordenadas;

    // Obtén el número de apariciones de las coordenadas
    const numberOfOccurrences = coordinatesCountMap.get(coordinates) || 0;

    // Si hay más de un elemento con las mismas coordenadas, muestra el número
    const shouldShowNumber = numberOfOccurrences > 1;

    // Extiende los límites solo si están inicializados
    if (!bounds) {
      bounds = new L.LatLngBounds(latLngTuple, latLngTuple);
    } else {
      bounds.extend(latLngTuple);
    }

    return (
        <Marker
          key={elem.id}
          position={latLngObject}
          icon={
            shouldShowNumber
              ? new L.DivIcon({
              className: 'custom-marker',
              html: `<div class="marker-content">${numberOfOccurrences}</div>`,
                })
              : customIcon
          }
          eventHandlers={{
            click: () => handleMarkerClick(filteredElements.filter(el => el.coordenadas === coordinates)),          }}
        />
      );
    });

  // Ajustar la vista del mapa para incluir todos los elementos
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { padding: [20, 20] });
    }
  }, [map, bounds]);

  return (
    <>
    {markers}
    {selectedMarker && (
      <Modal
        isOpen={selectedMarker !== null}
        onClose={() => {
          setSelectedMarker(null);
          setCurrentIndex(0);
        }}
        selectedMarker={selectedMarker}
        tipo={tipo}
        onNext={handleNextMarker}
        onPrev={handlePrevMarker}
      />
    )}
    </>
  );
};

function parseCoordenadas(coordString: string) {
  // Define la proyección UTM correspondiente (por ejemplo, UTM Zone 33T)
  proj4.defs('EPSG:25830', '+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs');

  let latLng: number[] = [];

  try {
    // Intenta analizar la cadena de coordenadas en formato WKT
    const geometry = wellknown.parse(coordString);

    if (
      geometry &&
      geometry.type === 'Point' &&
      Array.isArray(geometry.coordinates) &&
      geometry.coordinates.length === 2
    ) {
      const x = geometry.coordinates[0];
      const y = geometry.coordinates[1];

      // Realiza la conversión a latitud y longitud
      const lonLat = proj4('EPSG:25830', 'EPSG:4326', [x, y]);
      latLng = [lonLat[1], lonLat[0]];

      // latLng ahora contiene las coordenadas en formato [latitud, longitud]
      console.log('Latitud:', latLng[1]);
      console.log('Longitud:', latLng[0]);
    } else {
      console.error('El formato de coordenadas no es válido.');
    }
  } catch (error) {
    console.error('Error al analizar las coordenadas:', error);
  }

  return latLng;
}

export function parseMultiPolygon(multiPolygonWKT: string) {
  const polygons = wellknown.parse(multiPolygonWKT);

  if (polygons && polygons.type === 'MultiPolygon') {
    return polygons.coordinates[0].map(polygonCoordinates => {
      return polygonCoordinates.map(coordinate =>
        parseCoordenadas('POINT(' + coordinate[0] + ' ' + coordinate[1] + ')'),
      );
    });
  }

  return [];
}

export default MapComponent;