import '../../assets/scss/components/_filters.scss';
import React, { useState, useEffect } from 'react';
import Input from "../Input";
import Button from "../Button";
import Select from "../Select";
import CloseIcon from '../../assets/icons/close.svg';
import {InputType} from "../../shared/types";

type CampoElemento =
  | {
    name: string;
    label?: string;
    placeholder: string;
    typeElem: string;
    type?: InputType;
    flex?: string;
    onChange?: (value: string) => void;
  }
  | {
    name: string;
    label?: string;
    placeholder: string;
    typeElem: string;
    options?: { text: string; value: string }[];
    onChange?: (value: string) => void;
  };

interface FiltersComponentProps<T> {
  onHide: () => void;
  datos: T;
  camposFormulario: CampoElemento[];
  onAplicar: (filtros: T) => void;
  onReset: () => void;
}

function FiltersComponent<T>({ onHide,
                               datos: datosProp,
                               camposFormulario,
                               onAplicar,
                               onReset }: FiltersComponentProps<T>) {
  const [datos, setDatos] = useState<T>(datosProp);

  const resetFilters = () => {
    // Crear un objeto de reinicio
    const resetDatos: any = { ...datosProp }; // Inicializar resetDatos como Partial<T>

    // Establecer los campos select a "Sin seleccionar"
    camposFormulario.forEach((campo) => {
      if (campo.typeElem === 'select') {
        resetDatos[campo.name] = ""; // Establecer a vacío para "Sin seleccionar"
      }
    });

    setDatos(datosProp);  // Restablece los valores de los filtros a los valores iniciales
    onReset();
  };

  const handleSubmit = () => {
    onAplicar(datos);
  };

  const handleClose = () => {
    onHide();
  };

  const handleChange = (campo: keyof T, valor: string) => {
    setDatos((prevDatos) => ({ ...prevDatos, [campo]: valor }));
  };

  return (
    <div className="filters-component">
      <div className="modal-title">
        <h3>Filtros</h3>
        <img src={CloseIcon} alt="Cerrar" onClick={handleClose} />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="fields-container">
          {camposFormulario.map((elemento) => (
            <div>
              <React.Fragment key={elemento.name}>
                {elemento.typeElem === 'input' && (
                  <Input
                    key={elemento.name}
                    label={(elemento as { label?: string }).label || ''}
                    placeholder={elemento.placeholder}
                    value={String(datos[elemento.name as keyof T]) !== 'undefined' ? String(datos[elemento.name as keyof T]) : ''}
                    onChange={(value) => handleChange(elemento.name as keyof T, value)}
                  />
                )}

                {elemento.typeElem === 'select' && (
                  <Select
                      label={(elemento as { label?: string }).label || ''}
                      placeholder={elemento.placeholder}
                      options={[
                        { text: "Sin seleccionar", value: "" }, // Opción por defecto
                        ...(elemento as { options?: { text: string; value: string }[] }).options || [],
                      ]}
                      value={String(datos[elemento.name as keyof T]) !== 'undefined' ? String(datos[elemento.name as keyof T]) : ''}
                      onChange={(e) => {
                        handleChange(elemento.name as keyof T, e.target.value);
                        if (elemento.onChange) {
                          elemento.onChange(e.target.value); // Llama a la función onChange si existe
                        }
                      }}
                  />
                )}
              </React.Fragment>
            </div>
          ))}
        </div>
      </form>

      <div className="buttons-container">
        <Button color="transparent" onClick={resetFilters} type="button">Resetear filtros</Button>
        <Button color="outline" onClick={handleClose} type="button">Cancelar</Button>
        <Button color="primary" onClick={handleSubmit} type="submit">Aplicar</Button>
      </div>

    </div>
  );
}

export default FiltersComponent;