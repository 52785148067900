import { FC, ReactNode } from 'react';
import React from 'react';
import { Color } from '../../shared/types';
import Icon from '../Icon';
import '../../assets/scss/components/_button.scss';

interface BtnProps {
  /**
   * Boton con fondo transparente
   */
  outline?: boolean;

  /**
   * Icono de botón
   */
  icon?: string;

  /**
   * Color de botón
   */
  color: Color;

  /**
   * Ocupar 100% de su contenedor
   */
  block?: boolean;

  /**
   * Agregar clases de CSS propias
   */
  className?: string;

  /**
   * Función onClick
   */
  onClick?: () => void;

  /**
   * Contenido del botón
   */
  children: ReactNode;

  /**
   * Desactivar botón
   */
  isDisabled?: boolean;

  /**
   * Tipo
   */
  type?: 'submit' | 'button';
}

const Button: React.FC<BtnProps> = ({
  outline,
  icon,
  color,
  block,
  onClick,
  children,
  className,
  isDisabled: propIsDisabled,
  ...btnProps }) => {

  const combinedClassName = className ? `${className} btn-${color}` : `btn-${color}`;
  const isDisabledFromClass = color == 'disabled'; // Verifica si el color es "disabled", si viene siempre va a estar desactivado
  const isDisabled = propIsDisabled || isDisabledFromClass; // Combina con el parámetro isDisabled

  return (
    <button className={combinedClassName}
            onClick={isDisabled ? undefined : onClick}
            disabled={isDisabled}>
      {icon && <Icon name={icon} color={color} />}
      {children}
    </button>
  );
};

export default Button;