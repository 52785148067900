import React, { useState, createContext, ReactNode } from 'react';
import {AlertModalType} from '../shared/types';
import AlertModal from "../components/AlertModal/index";

export type ErrorContextType = {
    error: string | null;
    showErrorModal: (title: string, description: string, type: AlertModalType) => void;
    clearError: () => void;
};

export const ErrorContext = React.createContext<ErrorContextType>({
    error: null,
    showErrorModal: () => {},
    clearError: () => {}
});

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [error, setError] = useState<string | null>(null);
    const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);

    const showErrorModal = (title: string, description: string, type: AlertModalType): void => {
        setError(description);
        setModalConfig({ title, type, description });
    };

    const clearError = (): void => {
        setError(null);
    };

    const handleClose = () => {
        setModalConfig(null);
    };

    return (
      <ErrorContext.Provider value={{ error, showErrorModal, clearError }}>
          {children}
          {modalConfig && (
            <AlertModal
              title={modalConfig.title}
              description={modalConfig.description || ''}
              type={modalConfig.type}
              onSubmit={modalConfig.onSubmit ? modalConfig.onSubmit : handleClose}
              onClose={modalConfig.onClose ? modalConfig.onClose : handleClose}
            />
          )}
      </ErrorContext.Provider>
    );
};