import React, { useState, useEffect, useMemo } from 'react';
import '../../../assets/scss/pages/_gestion-usuarios.scss';
import DataTable, { TableColumn } from 'react-data-table-component';
import EditIcon from '../../../assets/icons/edit.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import AddIcon from '../../../assets/icons/plus-circle.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import CustomPagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../../context/AuthContext';
import {deleteData, getData} from '../../../API/index';
import { Role } from "../../../API/interfaces";
import { AlertModalType } from "../../../shared/types";
import AlertModal from "../../../components/AlertModal/index";

function RolesTab() {

  const elemPerPage = 15;
  const [searchText, setSearchText] = useState('');
  const urlGetDatos = '/api/roles';
  const navigate = useNavigate();
  const [roles, setRoles] = useState<Role[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<Role[]>();
  const { token, user, refreshAccessToken } = useAuth();
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // Filtrar roles según el texto de búsqueda
  const filteredRoles = useMemo(() => {
    return roles.filter(actuacion =>
      Object.values(actuacion).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [roles, searchText]);

  
  const columns: TableColumn<Role>[] = useMemo(
    () => [
      {
        name: 'Rol',
        selector: (row) => row.label,
        sortable: true,
        width: '72%'
      },
      {
        name: 'Usuarios con ese rol',
        selector: (row) => row.rolesCount,
        cell: row =>
          <div className="centered-header">{row.rolesCount}</div>,
        sortable: true,
        width: '13%'
      },
      {
        name: 'Editar',
        cell: row =>
          user.permisos.some((permiso: any) => permiso.nombre === 'edit-roles') && (
            <img
              src={EditIcon}
              alt="Añadir"
              onClick={() => navigate(`/gestion-usuarios/roles/editar/${row.id}`, { state: { from: 'Roles' } })}
            />
          ),
        width: '5.5%',
      },
      {
        name: 'Eliminar',
        cell: row =>
          user.permisos.some((permiso: any) => permiso.nombre === 'delete-roles') && (
            <img src={DeleteIcon} alt="Añadir" onClick={() => handleDelete(row)} style={{ marginLeft: '15px' }}/>
          ),
        width: '6%',
      },
    ],
    [selectedRows],
  );

  const handleDelete = (rol: Role) => {
    const config = {
      title: `Eliminar rol ${rol.label}`,
      description: 'Al continuar, se perderán los datos y no podrás recuperarlos',
      type: 'warning' as AlertModalType,
      onSubmit: async () => {
        await confirmDelete(rol.id.toString());
      },
      onClose: handleClose
    };

    setModalConfig(config);
  };

  const confirmDelete = async(id: string) => {
    let config: any;

    // Llama a la función onDelete pasándole formData
    if (id) {
      try {
        const response = await deleteData(`${urlGetDatos}/${id}`, navigate, token, refreshAccessToken);
        if(response.status === 200) {
          config = {
            title: `Rol borrado correctamente`,
            type: 'ok' as AlertModalType,
            onSubmit: () => {
              handleClose();
              window.location.reload();
            }
          };
        }
      } catch (error) {
        // Manejar la excepción aquí

        if ((error as any).response && (error as any).response.data && (error as any).response.data.message) {
          config = {
            title: 'Error al eliminar el rol',
            description: (error as any).response.data.message,
            type: 'error',
            onSubmit: () => {
              handleClose();
            }
          };
        }
      }

      setModalConfig(config);
    }
  };

  const handleClose = () => {
    setModalConfig(null);
  };

  // Llamada a la API para recibir los datos
  const fetchData = async (offset: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlGetDatos}?limit=${elemPerPage}&offset=${offset}`, navigate, token, refreshAccessToken);
      if (response && response.status === 200) {
        const transformedData = response.data.roles.map((item: any) => {

          return {
            id: item.id,
            label: item.label,
            name: item.name,
            description: item.description,
            rolesCount: item.usuarios.length
          }
        });

        setRoles(transformedData);
        setTotalRows(response.data.total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  return (
      <div>
        <div className="header-buttons">
          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'create-roles')) && (
            <Button color="white" onClick={() => navigate(`/gestion-usuarios/roles/crear`, { state: { from: 'Roles' } } )}>
              <img src={AddIcon} alt="Añadir rol" />
              Añadir rol
            </Button>
          )}

          <Input placeholder="Buscar" iconSrc={SearchIcon} onChange={handleSearch} />
        </div>

        {(filteredRoles && filteredRoles.length > 0) && (
          <DataTable
            columns={columns}
            data={filteredRoles}
            pagination
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            responsive
            paginationPerPage={elemPerPage}
            paginationTotalRows={roles ? roles.length : 0}
            paginationComponent={CustomPagination}
          />
        )}

        {(roles && searchText !== '' && (!filteredRoles || filteredRoles.length == 0)) && (
          <div className="no-data">No hay datos con los filtros aplicados</div>
        )}

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={(modalConfig.onSubmit ? modalConfig.onSubmit : handleDelete) as (() => void) | undefined}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleClose}
          />
        )}
      </div>
  );
}

export default RolesTab;