import React, { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import FileUploadButton from "../../../components/FileUploadButton";
import SuccessIcon from '../../../assets/icons/circle-check.svg';
import {Link} from "react-router-dom";
import {postData} from "../../../API/index";
import { AxiosResponse } from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate, NavigateFunction } from 'react-router-dom';

const EnviosTab = () => {

  const defaultSelected = 'actuaciones';
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const [fileToUpload, setFileToUpload] = useState<FileWithPath | null>(null);
  const { token, user, refreshAccessToken } = useAuth();
  const navigate = useNavigate() as NavigateFunction;

  const options = [
    {
      text: 'Actuaciones',
      value: 'actuaciones',
      permission: 'import-actuaciones'
    },
    {
      text: 'Inventario',
      value: 'inventario',
      permission: 'import-inventario'
    },
    {
      text: 'Piezas de recambio',
      value: 'piezasRecambio',
      permission: 'import-piezas-recambio'
    },
    {
      text: 'Dispositivos de medida',
      value: 'dispositivoMedida',
      permission: 'import-dispositivos-medida'
    },
    {
      text: 'Horario',
      value: 'horario',
      permission: 'import-horarios'
    },
  ];

  const urlCarga = '/api/carga/iho/';

  const handleUpload = async () => {
    if (fileToUpload) {
      const formData = new FormData();
      formData.append('archivo', fileToUpload);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const data = {
        archivo: ''
      };

      const response: AxiosResponse = await postData(`${urlCarga}${selectedOption}/fichero`, formData, token, refreshAccessToken, navigate, headers);

      if (response && (response.status >= 200 && response.status < 300)) {
        setUploadSuccess(true);
      } else {
        console.log('No se puede enviar el formulario. Asegúrate de seleccionar una opción y cargar un archivo.');
      }
    } else {
      console.log('No se ha seleccionado ningún archivo.');
    }
  };

  const handleNewUpload = () => {
    setSelectedOption(defaultSelected);
    setUploadSuccess(false);
    setFileToUpload(null);
  };

  const filteredOptions = user !== '' && user.permisos
  ? options.filter((option: any) => user.permisos.some((permission: any) => permission.nombre === option.permission))
  : [];

  return (
    <div className="envios-container">
      {!uploadSuccess && (
        <div>
          <Select
            label="Cargar en"
            options={filteredOptions}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          />

          <FileUploadButton type='archivo'
                            onSubmit={(file) => setFileToUpload(file)}
          />

          <Button color="primary" onClick={handleUpload}>Cargar</Button>
        </div>
      )}

      {uploadSuccess && (
        <div className="success-container">
          <img src={SuccessIcon} alt="Success Icon" />
          <p>Documento enviado a gestión de envíos</p>
          <Link to="/gestion-envios">
            <Button color="primary">Ir a gestión de envíos</Button>
          </Link>
          <Button color="transparent" onClick={handleNewUpload}>Realizar nuevo envío</Button>
        </div>
      )}

    </div>
  )
};

export default EnviosTab;