import React, { useState, useEffect } from 'react';
import '../../assets/scss/pages/_mapa-fuentes.scss';
import Navbar from "../../components/Navbar";
import MapaFuentesIcon from '../../assets/icons/pin-grey.svg';
import MapComponent from "./Mapa/index";
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import { AxiosResponse } from "axios";
import { useAuth } from '../../context/AuthContext';
import { getData, postData } from '../../API/index';

function MapaFuentesPage() {

  const location = useLocation();
  const { elements, tipo } = (location.state || {}) as { elements?: any; tipo?: any };
  const navigate = useNavigate();
  const urlGetDatos = '/api/iho/inventario';
  const elemPerPage = 5000;
  const offset = 0;
  const { token, refreshAccessToken } = useAuth();

  const [loadedElements, setLoadedElements] = useState<any[]>([]);

  useEffect(() => {
    // Verifica si venimos de la página de inicio y carga los elementos si es necesario
    if (location.state && (((location.state as { from?: string } | undefined)?.from === 'Home') ||
    (location.state && (location.state as { from?: string } | undefined)?.from === 'Menu'))) {
      // Si venimos de la home, pintamos todos los elementos de inventario de la BBDD
      fetchData();
    } else {
      // Utiliza los elementos proporcionados desde los listados
      setLoadedElements(elements || []);
    }
  }, [location.state]);

  const fetchData = async () => {
    try {
      let url = `${urlGetDatos}?limit=${elemPerPage}&offset=${offset}`;

      const response: AxiosResponse = await getData(url, navigate, token, refreshAccessToken);
      if (response && response.status === 200) {
        setLoadedElements(response.data.inventario || []);
      } else {
        // Maneja el caso de error, podrías mostrar un mensaje de error o redirigir a otra página
        navigate('/error');
      }
    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  /*const markers = [
    {
      id: 135,
      tipoElemento: 'Fuente ornamental',
      latitude: 51.505,
      longitude: -0.09,
      barrio: 'Cibeles',
      distrito: 'Madrid',
      clasificacion: 'IHO',
      nombreVia: 'P. del Prado',
      numeroVia: '12345',
      localizacion: '4u5hdb889299299',
      actuaciones: [
        {
          idActuacion: 12345567,
          fecha: '14/08/2023',
          estado: 'Programado',
        },
        {
          idActuacion: 35345244,
          fecha: '11/12/2023',
          estado: 'Ejecutado',
        },
        {
          idActuacion: 9238567456,
          fecha: '04/12/2023',
          estado: 'Cancelado',
        },
      ],
    },
    {
      id: 254,
      tipoElemento: 'Bombilla',
      latitude: 51.555,
      longitude: -0.09,
      barrio: 'Nombre del Barrio 2',
      distrito: 'Nombre del Distrito 2',
      clasificacion: 'Clasificación 2',
      nombreVia: 'Nombre de la Vía 2',
      numeroVia: 'Número de la Vía 2',
      localizacion: 'Ubicación 2',
    },
  ];*/

  return (
    <div className="mapa-fuentes-page">
      <Navbar />

      <div className="main-content">
        <div className="title">
          <img src={MapaFuentesIcon} alt="Mapa de fuentes" />
          <h2>Mapa de fuentes</h2>
        </div>

        <div className="container">
          <MapComponent elements={loadedElements} tipo={tipo} />
        </div>
      </div>
    </div>
  );
}

export default MapaFuentesPage;