import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/scss/pages/_home.scss';
import Navbar from "../../components/Navbar";
import logo from '../../assets/logos/iris-white.svg';
import WaterIcon from '../../assets/icons/water.svg';
import CommunicationsIcon from '../../assets/icons/communications.svg';
import CirclePlayIcon from '../../assets/icons/circle-play.svg';
import CircleDownloadIcon from '../../assets/icons/circle-download.svg';
import PinIcon from '../../assets/icons/pin.svg';
import UsersIcon from '../../assets/icons/users.svg';
import { useAuth } from '../../context/AuthContext';

function HomePage() {
  const { user } = useAuth();

  return (
    <div className="home-page">
      <Navbar />

      <div className="main-image">
        <div className="gradient">
          <img className="logo-img" src={logo} alt="Home"/>
        </div>
      </div>

      <div className="main-content">
        <h2>Módulos</h2>
        <div className="module-container">
          <Link to="/fuentes-ornamentales/inventario" state={{ from: 'Home' }}>
            <img src={WaterIcon} alt="Fuentes ornamentales" /> Fuentes ornamentales
          </Link>

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-comunicaciones')) && (
            <Link to="/comunicaciones" state={{ from: 'Home' }}>
              <img src={CommunicationsIcon} alt="Comunicaciones" /> Comunicaciones
            </Link>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-gestion-envios')) && (
            <Link to="/gestion-envios" state={{ from: 'Home' }}>
              <img src={CirclePlayIcon} alt="Gestión de envíos" /> Gestión de envíos
            </Link>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-descarga-ficheros')) && (
            <Link to="/descarga-ficheros" state={{ from: 'Home' }}>
              <img src={CircleDownloadIcon} alt="Descarga de ficheros" /> Descarga de ficheros
            </Link>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-mapa-fuentes')) && (
            <Link to="/mapa-fuentes" state={{ from: 'Home' }}>
              <img src={PinIcon} alt="Mapa de fuentes" /> Mapa de fuentes
            </Link>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-users')) && (
            <Link to="/gestion-usuarios/usuarios" state={{ from: 'Home' }}>
              <img src={UsersIcon} alt="Gestión de usuarios" /> Gestión de usuarios
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;