import React from 'react';
import { FC } from 'react';
import cn from 'classnames';
import '../../assets/scss/components/_checkbox.scss';

interface InputProps {

  /**
   * Indicar label
   */
  label?: string;

  /**
   * Input checked
   */
  checked?: boolean;

  /**
   * Handle checked
   */
  onChecked: (check: boolean) => void;

  /**
   * Handle checked
   */
  onClick?: () => void;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Checkbox: React.FC<InputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
     checked,
     onChecked,
     label,
     onClick,
     className,
     ...props
   }) => {

  const classNames = cn(
    'checkbox-container',
    className,
  );

  const labelClassName = cn({
    active: props.value,
  });

  const onEvent = () => {
    // Llamar a la función onChecked solo si está definida
    onChecked && onChecked(!checked);

    // Llamar a la función onClick solo si está definida
    onClick && onClick();
  };

  return (
    <div onClick={onEvent} className={classNames}>
      <input {...props} checked={checked} type="checkbox" />
      {label && (
        <label className={labelClassName}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;