import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '../../../assets/icons/close.svg';
import WaterIcon from '../../../assets/icons/water-grey.svg';
import CommunicationsIcon from '../../../assets/icons/communications-grey.svg';
import CirclePlayIcon from '../../../assets/icons/circle-play-grey.svg';
import CircleDownloadIcon from '../../../assets/icons/circle-download-grey.svg';
import PinIcon from '../../../assets/icons/pin-grey.svg';
import UsersIcon from '../../../assets/icons/users-grey.svg';
import { useAuth } from '../../../context/AuthContext';

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  const location = useLocation();
  const { user } = useAuth();

  return (
    <>
    {isOpen && (
      <div className="overlay" onClick={onClose}></div>
    )}
    <div className={`menu ${isOpen ? 'open' : ''}`}>
      <div>
        <p>Menu</p>
        <div onClick={onClose}>
          <img src={CloseIcon} alt="Cerrar Menú" />
        </div>
      </div>
      <ul>

        <li className={location.pathname.startsWith('/fuentes-ornamentales') ? 'active' : ''}>
          <Link to="/fuentes-ornamentales/inventario" state={{ from: 'Menu' }}>
            <img src={WaterIcon} alt="Fuentes ornamentales" /> Fuentes ornamentales
          </Link>
        </li>
        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-comunicaciones')) && (
          <li className={location.pathname === '/comunicaciones' ? 'active' : ''}>
            <Link to="/comunicaciones" state={{ from: 'Menu' }}>
              <img src={CommunicationsIcon} alt="Comunicaciones" /> Comunicaciones
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-gestion-envios')) && (
          <li className={location.pathname === '/gestion-envios' ? 'active' : ''}>
            <Link to="/gestion-envios" state={{ from: 'Menu' }}>
              <img src={CirclePlayIcon} alt="Gestión de envíos" /> Gestión de envíos
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-descarga-ficheros')) && (
          <li className={location.pathname === '/descarga-ficheros' ? 'active' : ''}>
            <Link to="/descarga-ficheros" state={{ from: 'Menu' }}>
              <img src={CircleDownloadIcon} alt="Descarga de ficheros" /> Descarga de ficheros
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-mapa-fuentes')) && (
          <li className={location.pathname === '/mapa-fuentes' ? 'active' : ''}>
            <Link to="/mapa-fuentes" state={{ from: 'Menu' }}>
              <img src={PinIcon} alt="Mapa de fuentes" /> Mapa de fuentes
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-users')) && (
          <li className={location.pathname === '/gestion-usuarios' ? 'active' : ''}>
            <Link to="/gestion-usuarios/usuarios" state={{ from: 'Menu' }}>
              <img src={UsersIcon} alt="Gestión de usuarios" /> Gestión de usuarios
            </Link>
          </li>
        )}

      </ul>
    </div>
    </>
  );
};

export default Menu;