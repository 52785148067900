import Pagination from '@material-ui/lab/Pagination';
import { ChangeEvent } from 'react';
import '../../assets/scss/components/_pagination.scss';
import PrevIcon from '../../assets/icons/arrow-left.svg';
import NextIcon from '../../assets/icons/arrow-right.svg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomPagination = ({ rowCount, currentPage, rowsPerPage, onChangePage }: any) => {
  const countPages = Math.ceil(rowCount / rowsPerPage);

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    onChangePage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < countPages) {
      onChangePage(currentPage + 1);
    }
  };

  return (
    <div className="pagination-container">
      <button onClick={handlePrevPage} disabled={currentPage === 1}>
        <img src={PrevIcon} alt="Anterior" />
        Anterior
      </button>
      <Pagination color="primary" count={countPages} page={currentPage} onChange={handleChangePage} />
      <button onClick={handleNextPage} disabled={currentPage === countPages}>
        Siguiente
        <img src={NextIcon} alt="Siguiente" />
      </button>
    </div>
  );
};

export default CustomPagination;
