import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import { appConfig } from '../config/app';
import { NavigateFunction } from 'react-router-dom';

const api = axios.create({
  baseURL: appConfig.apiConfig.baseURL,
});

// Función auxiliar para construir la URL completa
const buildFullURL = (url: string) => {
  const baseURL = `${appConfig.apiConfig.baseURL}`;
  const mURL = url.startsWith('/') ? url : `/${url}`;
  return baseURL + mURL;
};

// Función auxiliar para configurar los headers
const setHeaders = (token: string, additionalHeaders: any = {}) => {
  return { ...additionalHeaders, Authorization: `Bearer ${token}` };
};

export const postData = async (
  url: string,
  data: any,
  token: string,
  refreshAccessToken: () => Promise<string | null>,
  navigate: NavigateFunction,
  headers: any = {} // Hacer headers opcional
) => {
  try {
    const fullURL = buildFullURL(url);
    headers = setHeaders(token, headers);
    return await api.post(fullURL, data, { headers });
  } catch (error) {
    console.error('Error en la solicitud POST:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const newToken = await refreshAccessToken(); // Intenta refrescar el token

      if (newToken) {
        const fullURL = buildFullURL(url);
        headers = setHeaders(newToken, headers); // Actualiza los headers con el nuevo token
        return await api.post(fullURL, data, { headers });
      } else {
        navigate('/login');
      }
    }
    throw error;
  }
};

export const getData = async (
  url: string,
  navigate: NavigateFunction,
  token: string,
  refreshAccessToken: () => Promise<string | null>,
  headers: any = {} // Hacer headers opcional
) => {
  try {
    const fullURL = buildFullURL(url);
    headers = setHeaders(token, headers);

    return await api.get(fullURL, {headers});
  } catch (error) {
    console.error('Error en la solicitud GET:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const newToken = await refreshAccessToken(); // Intenta refrescar el token

      if (newToken) {
        const fullURL = buildFullURL(url);
        headers = setHeaders(newToken, headers); // Actualiza los headers con el nuevo token
        return await api.get(fullURL, { headers });
      } else {
        navigate('/login');
      }
    }
    throw error;
  }
};

export const patchData = async (
  url: string,
  navigate: NavigateFunction,
  token: string,
  refreshAccessToken: () => Promise<string | null>,
  headers: any = {} // Hacer headers opcional
) => {
  try {
    const fullURL = buildFullURL(url);
    headers = setHeaders(token, headers);
    return await api.patch(fullURL, headers);
  } catch (error) {
    console.error('Error en la solicitud PATCH:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const newToken = await refreshAccessToken(); // Intenta refrescar el token

      if (newToken) {
        const fullURL = buildFullURL(url);
        headers = setHeaders(newToken, headers); // Actualiza los headers con el nuevo token
        return await api.patch(fullURL, headers);
      } else {
        navigate('/login');
      }
    }
    throw error;
  }
};

export const putData = async (
  url: string,
  data: any,
  navigate: NavigateFunction,
  token: string,
  refreshAccessToken: () => Promise<string | null>,
  headers: any = {} // Hacer headers opcional
) => {
  try {
    const fullURL = buildFullURL(url);
    headers = setHeaders(token, headers);
    return await api.put(fullURL, data, { headers });
  } catch (error) {
    console.error('Error en la solicitud PATCH:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const newToken = await refreshAccessToken(); // Intenta refrescar el token

      if (newToken) {
        const fullURL = buildFullURL(url);
        headers = setHeaders(newToken, headers); // Actualiza los headers con el nuevo token
        return await api.put(fullURL, data, { headers });
      } else {
        navigate('/login');
      }
    }
    throw error;
  }
};

export const deleteData = async (
  url: string,
  navigate: NavigateFunction,
  token: string,
  refreshAccessToken: () => Promise<string | null>,
  headers: any = {} // Hacer headers opcional
) => {
  try {
    const fullURL = buildFullURL(url);
    headers = setHeaders(token, headers);
    return await api.delete(fullURL, { headers });
  } catch (error) {
    console.error('Error en la solicitud PATCH:', error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const newToken = await refreshAccessToken(); // Intenta refrescar el token

      if (newToken) {
        const fullURL = buildFullURL(url);
        headers = setHeaders(newToken, headers); // Actualiza los headers con el nuevo token
        return await api.delete(fullURL, { headers });
      } else {
        navigate('/login');
      }
    }
    throw error;
  }
};