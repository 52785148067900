import React, { useState, useEffect } from 'react';
import '../../assets/scss/pages/_gestion-envios.scss';
import Navbar from "../../components/Navbar";
import GestionEnviosIcon from '../../assets/icons/circle-play-grey.svg';
import DownloadIcon from '../../assets/icons/cloud-download.svg';
import DeleteIcon from '../../assets/icons/delete.svg';

import PauseIcon from '../../assets/icons/pause.svg';
import PlayIcon from '../../assets/icons/play.svg';
import FinishIcon from '../../assets/icons/circle-check-2.svg';

import {deleteData, getData, patchData} from "../../API/index";
import { AxiosResponse } from 'axios';
import { appConfig } from '../../config/app';

import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from "../../components/Pagination/index";
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import AlertModal from "../../components/AlertModal/index";
import { AlertModalType } from "../../shared/types";

export interface DataRow {
  id: number;
  control: string | JSX.Element;
  estado: string;
  modulo: string;
  seccion: string;
  fecha: string;
  total: number;
  finalizados: number;
  errores: number;
  // carga: string | JSX.Element;
  descarga: string | JSX.Element;
}

const NoDataComponent = () => (
  <div className="no-data-message">
    <p>No hay registros para mostrar</p>
  </div>
);

function GestionEnviosPage() {

  const elemPerPage = 12;
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [data, setData] = useState<DataRow[]>([]);
  const urlGetDatos = '/cargas-hilos';
  const navigate = useNavigate() as NavigateFunction;
  const { token, refreshAccessToken } = useAuth();
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Control',
      cell: (row) => {
        if (typeof row.control === 'string') {
          return <img
            src={row.control}
            alt="Control"
            style={{ width: '20px', cursor:'pointer' }}
            onClick={() => {
              if (row.control === PauseIcon) {
                pauseCarga(row.id);
              } else if (row.control === PlayIcon) {
                restartCarga(row.id);
              }
            }}
          />;
        }
        return <div></div>;
      }
    },
    {
      name: 'Estado',
      selector: (row) => row.estado,
      sortable: true
    },
    {
      name: 'Módulo',
      selector: (row) => row.modulo,
      sortable: true
    },
    {
      name: 'Sección',
      selector: (row) => row.seccion,
      sortable: true
    },
    {
      name: 'Fecha',
      selector: (row) => row.fecha,
      sortable: true
    },
    {
      name: 'Total',
      selector: (row) => row.total,
      sortable: true
    },
    {
      name: 'Finalizados',
      selector: (row) => row.finalizados,
      sortable: true
    },
    {
      name: 'Errores',
      selector: (row) => row.errores,
      sortable: true
    },/*{
      name: 'Carga',
      cell: (row) => {
        if (typeof row.carga === 'string') {
          return <img src={row.carga}
                      alt="Fichero de carga"
                      style={{ width: '23px', cursor:'pointer'}}
                      onClick={() => getArchivoCarga(row.id)} />;
        }
        return <div></div>;
      }
    },*/{
      name: 'Descarga',
      cell: (row) => {
        if (typeof row.descarga === 'string') {
          return <img src={row.descarga}
                      alt="Fichero de descarga"
                      style={{ width: '23px', cursor:'pointer' }}
                      onClick={() => getArchivoDescarga(row.id)}/>;
        }
        return <div></div>;
      }
    },
    {
      name: 'Eliminar',
      cell: row => {
        return <img src={DeleteIcon} alt="Eliminar" onClick={() => handleDelete(row)} style={{ marginLeft: '15px' }}/>
      },
      width: '6%',
    },
  ];

  const getArchivoCarga = async (cargaId: number) => {
    // TODO Llamar a API pasando el cargaId

    /*if (res && res.success && res.file) {
      window.open(`${appConfig.apiConfig.baseURL}/${res.file.replace('../', '')}`, '_blank');
    } else {
      console.log('No se ha podido obtener el archivo de carga desde el servidor')
    }*/
    const response: AxiosResponse = await getData(`/cargas-hilos/fichero-carga/${cargaId}`, navigate, token, refreshAccessToken);
    if (response && response.status === 200) {
      window.open(`${appConfig.apiConfig.baseURL}/${response?.data?.file}`, '_blank');
    } else {
      console.log('No se ha podido obtener el archivo de carga desde el servidor')
    }
  };

  const getArchivoDescarga = async (cargaId: number) => {
    const response: AxiosResponse = await getData(`/cargas-hilos/fichero-descarga/${cargaId}`, navigate, token, refreshAccessToken);
    if (response && response.status === 200) {
      window.open(`${appConfig.apiConfig.baseURL}/${response?.data?.file}`, '_blank');
    } else {
      console.log('No se ha podido obtener el archivo de descarga desde el servidor')
    }
  };

  const pauseCarga = async (cargaId: number) => {
    const response: AxiosResponse = await patchData(`/cargas-hilos/pausar/${cargaId}`, navigate, token, refreshAccessToken);
    if (response && response.status === 200) {
      fetchData(currentPage);
    }
  };

  const restartCarga = async (cargaId: number) => {
    const response: AxiosResponse = await patchData(`/cargas-hilos/reanudar/${cargaId}`, navigate, token, refreshAccessToken);
    if (response && response.status === 200) {
      fetchData(currentPage);
    }
  };

  // Llamada a la API para recibir los datos
  const fetchData = async (offset: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlGetDatos}?limit=${elemPerPage}&offset=${offset}`, navigate, token, refreshAccessToken);
      if (response && response.status === 200) {
        const transformedData = response.data.rows.map((item: any) => {
          let control = FinishIcon;
          if (item.estado === 'En pausa') {
            control = PlayIcon;
          } else if (['Pendiente', 'En cola', 'En ejecución'].includes(item.estado)) {
            control = PauseIcon;
          }

          return {
            id: item.id,
            control: control, // Define cómo obtener el icono de pausa, reproducción o finalización según el estado,
            estado: item.estado,
            modulo: item.modulo,
            seccion: item.operacion,
            fecha: item.fecha,
            total: item.total,
            finalizados: item.finalizados,
            errores: item.errores,
            carga: DownloadIcon,
            descarga: DownloadIcon
            };
        });

        setData(transformedData);
        setTotalRows(response.data.total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    fetchData(0); // Llama a la función fetchData cuando el componente se monta

    // Configurar un intervalo para recargar los datos cada 5 segundos
    const intervalId = setInterval(() => {
      fetchData(currentPage);
    }, 5000);

    // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []); // El segundo argumento [] asegura que useEffect solo se ejecute una vez al montar el componente

  const handlePageChange = (currentPage: number) => {
    const newOffset = (currentPage - 1) * elemPerPage;
    setCurrentPage(newOffset);
    fetchData(newOffset);
  };

  const handleDelete = (row: DataRow) => {
    const config = {
      title: `Eliminar carga ${row.id}`,
      description: 'Al continuar, se perderán los datos y no podrás recuperarlos',
      type: 'warning' as AlertModalType,
      onSubmit: async () => {
        await confirmDelete(row.id.toString());
      },
      onClose: handleClose
    };

    setModalConfig(config);
  };

  const confirmDelete = async(id: string) => {
    let config: any;

    if (id) {
      const response = await deleteData(`${urlGetDatos}/${id}`, navigate, token, refreshAccessToken);
      if(response.status === 200) {
        config = {
          title: `Carga borrada correctamente`,
          type: 'ok' as AlertModalType,
          onSubmit: () => {
            handleClose();
            window.location.reload();
          }
        };
      } else {
        if (response?.data.error) {
          config = {
            title: 'Error al eliminar: ' + response?.data.error,
            type: 'error'
        };
        }
      }
      setModalConfig(config);
    }
  };

  const handleClose = () => {
    setModalConfig(null);
  };

  return (
    <div className="gestion-envios-page">
      <Navbar />

      <div className="main-content">
        <div className="title">
          <img src={GestionEnviosIcon} alt="Gestión de envíos" />
          <h2>Gestión de envíos</h2>
        </div>

        <DataTable
          responsive
          columns={columns}
          data={data}
          progressPending={false}
          pagination
          paginationServer
          highlightOnHover
          paginationPerPage={elemPerPage}
          paginationTotalRows={totalRows}
          paginationComponent={CustomPagination}
          onChangePage={(page) => handlePageChange(page)}
          noDataComponent={<NoDataComponent />}
        />
      </div>

      {modalConfig && (
        <AlertModal
          title={modalConfig.title}
          description={modalConfig.description}
          type={modalConfig.type}
          onSubmit={(modalConfig.onSubmit ? modalConfig.onSubmit : handleDelete) as (() => void) | undefined}
          onClose={modalConfig.onClose ? modalConfig.onClose : handleClose}
        />
      )}
    </div>
  );
}

export default GestionEnviosPage;