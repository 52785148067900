import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CreatAndEditGeneralPage, { CampoElemento, DatosType } from "../../../CrearEditarGeneral";
import { AxiosResponse } from 'axios';
import {getData, postData, putData, deleteData} from '../../../../API/index';
import { useAuth } from '../../../../context/AuthContext';

function CrearEditarRolesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string } | undefined)?.from || 'atrás';
  const { id } = useParams();
  const [datos, setDatos] = useState<DatosType>({});
  const urlDatos = '/api/roles';
  const { token, refreshAccessToken } = useAuth();

  const camposFormulario: CampoElemento[] = [
    { id: 'nombreRol', label: 'Nombre del rol',  flex: '4', elementos: [
      { name: 'label', placeholder: 'Nombre del rol', flex: '4', typeElem: 'input', type: 'text' },
    ]},
    { id: 'accesos', label: '', flex: '4', elementos: [
      { name: 'accesos', label: 'Accesos y permisos', flex: '4', typeElem: 'permissions'}
    ]}
  ];

  useEffect(() => {
    if (id) {
      // TODO llamamos a la api para coger la actuación a editar
      fetchData(Number(id));
    }
  }, [id]);

  // Llamada a la API para recibir los datos
  const fetchData = async (id: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlDatos}/${id}`, navigate, token, refreshAccessToken);
      if (response && response.status === 200) {
        const datos = {
          label: response.data.label,
          // Reformatear los permisos para que sea un array de IDs
          permisos: response.data.permisos.map((permiso: any) => permiso.id),
        };
        // Establecer los datos en el estado
        setDatos(datos);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const handleSaveElem = async (formData?: DatosType): Promise<AxiosResponse<any, any> | null> => {    // Lógica para guardar los datos
    let response: AxiosResponse;

    if(formData) {
      if (id) { // Si hay un ID, estás editando
        response = await putData(`${urlDatos}/${id}`, formData, navigate, token, refreshAccessToken);
      } else { // Si no hay un ID, estás creando
        response = await postData(`${urlDatos}`, formData, token, refreshAccessToken, navigate);
      }
      return response;
    } else {
      return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
    }
  };

  const handleDeleteElem = async(): Promise<AxiosResponse<any, any>> => {
    // Lógica para borrar los datos
    if (id) {
      return await deleteData(`${urlDatos}/${id}`, navigate, token, refreshAccessToken);
    }
    return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (

    <CreatAndEditGeneralPage
      title={id ? 'Editar Rol' : 'Nuevo Rol'}
      elementsType='rol'
      datos={datos}
      camposFormulario={camposFormulario}
      onSave={handleSaveElem}
      onCancel={handleCancel}
      onDelete={handleDeleteElem}
    />
  );
}

export default CrearEditarRolesPage;